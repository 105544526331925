<template>
  <div class="right">
    <div class="youhui">
      <div class="youhui_L">优惠券</div>
      <!-- <div class="youhui_R">领取更多优惠券</div> -->
    </div>
    <div class="menu">
      <div class="qiehuan">
        <div
          class="daohang"
          :class="[
            { bian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in paihang"
          :key="index"
          @click="selectedTabItem(index, item.id)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="search" @click="shuoming">优惠券使用说明</div>
    </div>
    <div class="neirong" v-if="TabIndex == 0">
      <div class="list" v-for="(item, index) in TabList" :key="index">
        <img src="../../../assets/order/lan.png" alt="" />
        <div class="list_T">
          <div class="price">
            ￥{{ item.discountAmount }}<span class="biao"></span>
          </div>
          <div class="man">满{{ item.orderAmount }}元可用</div>
          <div class="time">{{ item.useBeginTime }}-{{ item.useEndTime }}</div>
        </div>
        <div class="list_B">
          <div class="pinlei">
            限品类:<span class="quanpin">
              {{ item.useRange == 2 ? '全品类' : item.useName }}
            </span>
          </div>
          <div class="pingtai">
            限店铺:<span class="quanpin">{{ item.storeName }}</span>
          </div>
          <div class="pingtai">
            券编号:<span class="quanpin">{{ item.couponNumber }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="neirong3" v-if="TabIndex == 1">
      <div class="list3" v-for="(item, index) in TabList" :key="index">
        <img src="../../../assets/order/hui.png" alt="" />
        <div class="list_T3">
          <div class="price3">
            ￥{{ item.discountAmount }}<span class="biao3"></span>
          </div>
          <div class="man3">满{{ item.orderAmount }}元可用</div>
          <div class="time3">{{ item.useBeginTime }}-{{ item.useEndTime }}</div>
        </div>
        <div class="list_B3">
          <div class="pinlei3">
            限品类:<span class="quanpin3">{{
              item.useRange == 2 ? '全品类' : item.useName
            }}</span>
          </div>
          <div class="pingtai3">
            限店铺:<span class="quanpin3">{{ item.storeName }}</span>
          </div>
          <div class="pingtai3">
            券编号:<span class="quanpin3">{{ item.couponNumber }}</span>
          </div>
          <div class="pingtai3">
            {{ $t('label.Order_number2') }}:<span class="quanpin3">{{ item.orderNo }}</span>
          </div>
        </div>
        <div class="shiyong3">
          <img src="../../../assets/order/shiyong.png" alt="" />
        </div>
      </div>
    </div>
    <div class="neirong3" v-if="TabIndex == 2">
      <div class="list3" v-for="(item, index) in TabList" :key="index">
        <img src="../../../assets/order/hui.png" alt="" />
        <div class="list_T3">
          <div class="price3">
            ￥{{ item.discountAmount }}<span class="biao3"></span>
          </div>
          <div class="man3">满{{ item.orderAmount }}元可用</div>
          <div class="time3">{{ item.useBeginTime }}-{{ item.useEndTime }}</div>
        </div>
        <div class="list_B3">
          <div class="pinlei3">
            限品类:<span class="quanpin3">{{
              item.useRange == 2 ? '全品类' : item.useName
            }}</span>
          </div>
          <div class="pingtai3">
            限店铺:<span class="quanpin3">{{ item.storeName }}</span>
          </div>
          <div class="pingtai3">
            券编号:<span class="quanpin3">{{ item.couponNumber }}</span>
          </div>
        </div>
        <div class="shiyong3">
          <img src="../../../assets/order/guoqi.png" alt="" />
        </div>
      </div>
    </div>
    <el-pagination
      v-if="page.total != 0"
      @current-change="pagechange"
      :current-page="page.current"
      style="text-align: right;margin-top: 20px"
      background
      :total="page.total"
      :page-size="page.size"
      layout="prev, pager, next, jumper, ->, total"
    >
    </el-pagination>
    <el-dialog
      :title="articleTypeName"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
    >
      <div class="el-dialog-div"><span v-html="content"></span></div>
    </el-dialog>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      paihang: [
        {
          id: 0,
          title: '可用优惠券',
        },
        {
          id: 1,
          title: '已用优惠券',
        },
        {
          id: 2,
          title: '过期优惠券',
        },
      ],
      TabIndex: 0,
      TabList: [],
      page: {
        total: 1,
        size: 12,
        current: 1,
      },
      content: '',
      articleTypeName: '',
      centerDialogVisible: false,
    };
  },
  created() {
    this.selectedTabItem(0);
  },
  methods: {
    shuoming() {
      this.centerDialogVisible = true;
      get('f/api/articleType/selectById?id=21').then((res) => {
        this.articleTypeName = res.data.data.articleTypeName;
        this.content = res.data.data.content;
      });
    },
    selectedTabItem(index, e) {
      get(
        'api/shopcoupon/selectShopMemberCouponRecord?couponStatus=' +
          index +
          '&pageNum=' +
          this.page.current +
          '&pageSize=' +
          this.page.size
      ).then((res) => {
        this.TabIndex = index;
        let data = res.data.data;
        this.TabList = data.list;
        this.page.total = data.total;
      });
    },
    pagechange(p) {
      this.page.current = p;
      get(
        'api/shopcoupon/selectShopMemberCouponRecord?couponStatus=' +
          this.TabIndex +
          '&pageNum=' +
          this.page.current +
          '&pageSize=' +
          this.page.size
      ).then((res) => {
        let data = res.data.data;
        this.TabList = data.list;
        this.page.total = data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dialog-div {
  min-height: 300px;
}
.right {
  width: 976px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  padding-right: 30px;
  .youhui {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .youhui_L {
      font-weight: 600;
      font-size: 26px;
      color: #616161;
    }
    .youhui_R {
      font-size: 18px;
      color: #666;
      font-weight: 600;
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .qiehuan {
      height: 80px;
      display: flex;
      align-items: center;
      cursor: default;
      .daohang {
        font-size: 15px;
        color: #272727;
        margin-right: 25px;
        cursor: pointer;
      }
      .bian {
        color: #1a4fff;
      }
    }
    .search {
      font-size: 14px;
      color: #e9883b;
      cursor: pointer;
    }
  }
  .neirong {
    .list {
      width: 237px;
      display: inline-block;
      height: 488px;
      position: relative;
      margin-right: 5px;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
      }
      .list_T {
        position: absolute;
        top: 0;
        left: 0;
        width: 237px;
        height: 170px;
        .price {
          width: 100%;
          text-align: center;
          color: #fff;
          font-size: 32px;
          font-weight: 600;
          margin-top: 45px;
          .biao {
            color: #035be0;
            font-size: 14px;
            font-weight: normal;
            margin-left: 5px;
          }
        }
        .man {
          width: 100%;
          color: #fff;
          font-size: 14px;
          text-align: center;
          margin-top: 15px;
        }
        .time {
          width: 100%;
          text-align: center;
          font-size: 12px;
          color: #0559e0;
          margin-top: 15px;
        }
      }
      .list_B {
        position: absolute;
        top: 170px;
        left: 0;
        width: 100%;
        .pinlei {
          font-size: 14px;
          color: #707070;
          margin-left: 10px;
          margin-top: 20px;
          .quanpin {
            color: #999;
            font-size: 12px;
          }
        }
        .pingtai {
          font-size: 14px;
          color: #707070;
          margin-left: 10px;
          margin-top: 15px;
          .quanpin {
            color: #999;
            font-size: 12px;
          }
        }
        .shiyong {
          width: 124px;
          height: 31px;
          line-height: 31px;
          text-align: center;
          border: 2px solid #3665fd;
          color: #3665fd;
          border-radius: 15px;
          margin: 80px auto;
        }
      }
    }
  }
  .neirong3 {
    .list3 {
      width: 237px;
      height: 488px;
      position: relative;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
      }
      .list_T3 {
        position: absolute;
        top: 0;
        left: 0;
        width: 237px;
        height: 170px;
        .price3 {
          width: 100%;
          text-align: center;
          color: #fff;
          font-size: 32px;
          font-weight: 600;
          margin-top: 45px;
          .biao3 {
            color: #7f7f7f;
            font-size: 14px;
            font-weight: normal;
            margin-left: 5px;
          }
        }
        .man3 {
          width: 100%;
          color: #fff;
          font-size: 14px;
          text-align: center;
          margin-top: 15px;
        }
        .time3 {
          width: 100%;
          text-align: center;
          font-size: 12px;
          color: #7f7f7f;
          margin-top: 15px;
        }
      }
      .list_B3 {
        position: absolute;
        top: 170px;
        left: 0;
        width: 100%;
        .pinlei3 {
          font-size: 14px;
          color: #707070;
          margin-left: 10px;
          margin-top: 20px;
          .quanpin3 {
            color: #999;
            font-size: 12px;
          }
        }
        .pingtai3 {
          font-size: 14px;
          color: #707070;
          margin-left: 10px;
          margin-top: 15px;
          .quanpin3 {
            color: #999;
            font-size: 12px;
          }
        }
      }
      .shiyong3 {
        position: absolute;
        right: 40px;
        bottom: 55px;
        img {
          width: 67px;
          height: 67px;
        }
      }
    }
  }
}
</style>
